var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-sidebar", {
        attrs: {
          id: "sidebar-task-handler",
          "sidebar-class": "sidebar-lg",
          "bg-variant": "white",
          shadow: "",
          "no-header": "",
          right: "",
          visible: _vm.showElementEdit,
        },
        on: {
          change: function (val) {
            return _vm.$emit("update:show-element-edit-form", val)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1",
                  },
                  [
                    _c("div", [_vm._v(" 문항 정보 변경 ")]),
                    _c(
                      "div",
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer",
                          attrs: { icon: "TrashIcon" },
                          on: {
                            click: function ($event) {
                              _vm.$emit("remove", _vm.item)
                              hide()
                            },
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: hide },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("validation-observer", {
                  ref: "refFormObserver",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          return [
                            _c(
                              "b-form",
                              {
                                staticClass: "p-2",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.onSubmit)
                                  },
                                  reset: function ($event) {
                                    $event.preventDefault()
                                    return _vm.resetForm($event)
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "문항 유형",
                                      "label-for": "survey-element-type",
                                    },
                                  },
                                  [
                                    _c("h5", [
                                      _vm._v(_vm._s(_vm.item.typeName)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "문항 제목",
                                      "label-for": "survey-element-title",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "문항 제목",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "survey-element-title",
                                                    autofocus: "",
                                                    state:
                                                      errors.length > 0
                                                        ? false
                                                        : null,
                                                    placeholder:
                                                      "문항 제목(질문 내용) 입력",
                                                  },
                                                  model: {
                                                    value: _vm.item.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.title",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm.item.type !== 50
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "필수 여부",
                                            "label-for":
                                              "survey-element-required",
                                          },
                                        },
                                        [
                                          _c("b-form-radio-group", {
                                            attrs: {
                                              id: "survey-element-required",
                                              options: _vm.codes.required,
                                              "value-field": "code",
                                              "text-field": "label",
                                            },
                                            model: {
                                              value: _vm.item.isRequired,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "isRequired",
                                                  $$v
                                                )
                                              },
                                              expression: "item.isRequired",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 2 || _vm.item.type === 5
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "최소 선택수",
                                            "label-for":
                                              "survey-element-choice-min-check",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "survey-element-choice-min-check",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.minCheck,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "minCheck",
                                                  $$v
                                                )
                                              },
                                              expression: "item.minCheck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "최대 선택수",
                                            "label-for":
                                              "survey-element-choice-max-check",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "survey-element-choice-max-check",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.maxCheck,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "maxCheck",
                                                  $$v
                                                )
                                              },
                                              expression: "item.maxCheck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 1 ||
                                _vm.item.type === 2 ||
                                _vm.item.type === 5
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "선택 항목",
                                            "label-for":
                                              "survey-element-select-items",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-select-items",
                                                  value: _vm.item.choices
                                                    ? _vm.item.choices.length +
                                                      "개 항목"
                                                    : "0개 항목",
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openChoiceConfig(
                                                            _vm.item.type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 1 && _vm.item.isFinishChoice
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "설문 비대상 종료 메시지",
                                            "label-for":
                                              "survey-element-finish-message",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-finish-message",
                                                  value: _vm.item.finishMessage
                                                    ? _vm.item.finishMessage
                                                    : "기본 비대상 종료 메시지로 안내",
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openEditor(
                                                            "finishMessage",
                                                            _vm.item
                                                              .finishMessage,
                                                            "비대상 종료 메시지 변경",
                                                            "변경할 메시지 내용을 아래 입력 후 적용해 주세요. 메시지 팝업 사이즈로 인해 의도치 않은 줄바꿈이 발생할 수 있으므로 적용 후 테스트 환경에서 확인하여 주시기 바랍니다."
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 1 ||
                                _vm.item.type === 2 ||
                                _vm.item.type === 5
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "보기 순서 랜덤 여부",
                                            "label-for":
                                              "survey-element-random-order",
                                          },
                                        },
                                        [
                                          _c("b-form-radio-group", {
                                            attrs: {
                                              id: "survey-element-random-order",
                                              options: _vm.codes.randomOrder,
                                              "value-field": "code",
                                              "text-field": "label",
                                            },
                                            model: {
                                              value: _vm.item.isRandomOrder,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "isRandomOrder",
                                                  $$v
                                                )
                                              },
                                              expression: "item.isRandomOrder",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 3
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "답변 형식",
                                            "label-for":
                                              "survey-element-text-type",
                                          },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              id: "survey-element-text-type",
                                              options: _vm.codes.textType,
                                              placeholder: "전체",
                                              reduce: function (option) {
                                                return option.code
                                              },
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeTextType(
                                                  _vm.item
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.item.textType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "textType",
                                                  $$v
                                                )
                                              },
                                              expression: "item.textType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 3 && _vm.item.textType === 2
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "입력범위",
                                            "label-for":
                                              "survey-element-min-check",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            staticStyle: {
                                              width: "45%",
                                              display: "inline-block",
                                            },
                                            attrs: {
                                              id: "survey-element-min-check",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.minCheck,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "minCheck",
                                                  $$v
                                                )
                                              },
                                              expression: "item.minCheck",
                                            },
                                          }),
                                          _c(
                                            "label",
                                            { staticClass: "ml-50 mr-50" },
                                            [_vm._v("~")]
                                          ),
                                          _c("b-form-input", {
                                            staticStyle: {
                                              width: "45%",
                                              display: "inline-block",
                                            },
                                            attrs: {
                                              id: "survey-element-max-check",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.maxCheck,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "maxCheck",
                                                  $$v
                                                )
                                              },
                                              expression: "item.maxCheck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 4
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "입력란 높이(라인수)",
                                            "label-for": "survey-element-rows",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "survey-element-rows",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.rows,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.item, "rows", $$v)
                                              },
                                              expression: "item.rows",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 4
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "최소 글자수",
                                            "label-for":
                                              "survey-element-text-min-check",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "survey-element-text-min-check",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.minCheck,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "minCheck",
                                                  $$v
                                                )
                                              },
                                              expression: "item.minCheck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "최대 글자수",
                                            "label-for":
                                              "survey-element-text-max-check",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "survey-element-text-max-check",
                                              type: "number",
                                            },
                                            model: {
                                              value: _vm.item.maxCheck,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "maxCheck",
                                                  $$v
                                                )
                                              },
                                              expression: "item.maxCheck",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 6
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "입력 항목",
                                            "label-for":
                                              "survey-element-select-input-items",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-select-input-items",
                                                  value: _vm.item.choices
                                                    ? _vm.item.choices.length +
                                                      "개 항목"
                                                    : "0개 항목",
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openChoiceInputConfig(
                                                            _vm.item.type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 6
                                  ? [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "비율 합계",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "비율 합계",
                                                        "label-for":
                                                          "survey-element-sum-check",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "survey-element-sum-check",
                                                          type: "number",
                                                          placeholder:
                                                            "숫자 입력",
                                                          state:
                                                            errors.length > 0
                                                              ? false
                                                              : null,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.sumCheck,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "sumCheck",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.sumCheck",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 7 || _vm.item.type === 9
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "문항 타입",
                                            "label-for":
                                              "survey-element-item-sub-type",
                                          },
                                        },
                                        [
                                          _c("b-form-radio-group", {
                                            attrs: {
                                              id: "survey-element-item-sub-type",
                                              options: _vm.filteredItemSubType(
                                                _vm.item.type
                                              ),
                                              "value-field": "code",
                                              "text-field": "label",
                                            },
                                            model: {
                                              value: _vm.item.subType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "subType",
                                                  $$v
                                                )
                                              },
                                              expression: "item.subType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 7
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "선택 항목",
                                            "label-for":
                                              "survey-element-select-grid-items",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-select-grid-items",
                                                  value: _vm.item.choices
                                                    ? _vm.choiceGridDisplayCount(
                                                        _vm.item.choices
                                                      )
                                                    : "0개 항목",
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openChoiceGridConfig(
                                                            _vm.item.type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 8
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "척도 단계",
                                            "label-for":
                                              "survey-element-scale-level",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "척도 단계",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          id: "survey-element-scale-level",
                                                          options:
                                                            _vm.codes
                                                              .scaleLevel,
                                                          reduce: function (
                                                            option
                                                          ) {
                                                            return option.code
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.scaleLevel,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "scaleLevel",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.scaleLevel",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "시작 점수",
                                            "label-for":
                                              "survey-element-scale-min-value",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "시작 점수",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          id: "survey-element-scale-min-value",
                                                          options:
                                                            _vm.codes.minValue,
                                                          reduce: function (
                                                            option
                                                          ) {
                                                            return option.code
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.minValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "minValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.minValue",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", [
                                            _vm._v(
                                              "척도 표시(선택, *최대 50자까지 입력 가능)"
                                            ),
                                          ]),
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "survey-element-scale-min-desc",
                                              type: "text",
                                              maxlength: "50",
                                              placeholder: "좌측 문구 입력",
                                            },
                                            model: {
                                              value: _vm.item.minDesc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "minDesc",
                                                  $$v
                                                )
                                              },
                                              expression: "item.minDesc",
                                            },
                                          }),
                                          _c("b-form-input", {
                                            staticClass: "mt-50",
                                            attrs: {
                                              id: "survey-element-scale-med-desc",
                                              type: "text",
                                              maxlength: "50",
                                              placeholder: "중앙 문구 입력",
                                            },
                                            model: {
                                              value: _vm.item.medDesc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "medDesc",
                                                  $$v
                                                )
                                              },
                                              expression: "item.medDesc",
                                            },
                                          }),
                                          _c("b-form-input", {
                                            staticClass: "mt-50",
                                            attrs: {
                                              id: "survey-element-scale-max-desc",
                                              type: "text",
                                              maxlength: "50",
                                              placeholder: "우측 문구 입력",
                                            },
                                            model: {
                                              value: _vm.item.maxDesc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "maxDesc",
                                                  $$v
                                                )
                                              },
                                              expression: "item.maxDesc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 9
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "불러올 문항(1개 선택)",
                                            "label-for":
                                              "survey-element-ref-element-id",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "불러올 문항",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.filterPipingElements(
                                                              _vm.item
                                                                .prevElements
                                                            ),
                                                          reduce: function (
                                                            option
                                                          ) {
                                                            return option.id
                                                          },
                                                          placeholder:
                                                            "불러올 문항",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item
                                                              .refElementId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "refElementId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.refElementId",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm.item.type === 50
                                  ? [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "하위 문항",
                                            "label-for":
                                              "survey-element-group-items",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-group-items",
                                                  value: _vm.item
                                                    .groupSubElementCount
                                                    ? _vm.item
                                                        .groupSubElementCount +
                                                      "개 항목"
                                                    : "0개 항목",
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openElementGroupConfig(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label:
                                        "설명 문구(*문항 제목 아래에 질문을 설명하기 위한 표시 문구)",
                                      "label-for": "survey-element-description",
                                    },
                                  },
                                  [
                                    _c("tinymce-editor", {
                                      attrs: {
                                        id: "survey-element-description",
                                      },
                                      model: {
                                        value: _vm.item.description,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "description", $$v)
                                        },
                                        expression: "item.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.item.type === 100
                                  ? [
                                      _c("b-row", {
                                        staticStyle: {
                                          "border-top": "1px solid #ebe9f1",
                                        },
                                      }),
                                      _c(
                                        "b-alert",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { show: "", variant: "dark" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-body" },
                                            [
                                              _c("span", [
                                                _vm._v("* 자동제출 유형 설정"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1" },
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "조회시작일",
                                                    "label-for":
                                                      "survey-element-start-date",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "조회시작일",
                                                      rules: {
                                                        required: true,
                                                        before:
                                                          _vm.item.endDate,
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-datepicker",
                                                                {
                                                                  attrs: {
                                                                    id: "survey-element-start-date",
                                                                    state:
                                                                      errors.length >
                                                                      0
                                                                        ? false
                                                                        : null,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .startDate,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "startDate",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.startDate",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "조회종료일",
                                                    "label-for":
                                                      "survey-element-end-date",
                                                  },
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "조회종료일",
                                                      rules: {
                                                        required: true,
                                                        after:
                                                          _vm.item.startDate,
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-datepicker",
                                                                {
                                                                  attrs: {
                                                                    id: "survey-element-end-date",
                                                                    state:
                                                                      errors.length >
                                                                      0
                                                                        ? false
                                                                        : null,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.item
                                                                        .endDate,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.item,
                                                                          "endDate",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.endDate",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "상병코드",
                                            "label-for":
                                              "survey-element-diseases",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "상병코드",
                                              rules: {
                                                requiredIf: !_vm.item.drugs,
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "b-input-group",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "survey-element-diseases",
                                                              value:
                                                                _vm.item
                                                                  .diseases,
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              readonly: "",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        _vm.openEditor(
                                                                          "diseases",
                                                                          _vm
                                                                            .item
                                                                            .diseases,
                                                                          "상병코드 수정",
                                                                          "항목 당 ,(콤마)로 구분합니다. 코드 뒤에 %(퍼센트)를 붙여 다중 검색이 가능합니다."
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 변경 "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                errors.length >
                                                                  0
                                                                  ? "상병코드 또는 청구코드 중 하나는 반드시 입력되어야 합니다."
                                                                  : ""
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "청구코드(약품,검사)",
                                            "label-for": "survey-element-drugs",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "청구코드(약품,검사)",
                                              rules: {
                                                requiredIf: !_vm.item.diseases,
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c(
                                                        "b-input-group",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "survey-element-drugs",
                                                              value:
                                                                _vm.item.drugs,
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              readonly: "",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        _vm.openEditor(
                                                                          "drugs",
                                                                          _vm
                                                                            .item
                                                                            .drugs,
                                                                          "청구코드(약품,검사) 수정",
                                                                          "항목 당 ,(콤마)로 구분합니다."
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 변경 "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                errors.length >
                                                                  0
                                                                  ? "상병코드 또는 청구코드 중 하나는 반드시 입력되어야 합니다."
                                                                  : ""
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "자동제출 유형",
                                            "label-for":
                                              "survey-element-auto-type",
                                          },
                                        },
                                        [
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "자동제출 유형",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("v-select", {
                                                        attrs: {
                                                          id: "survey-element-auto-type",
                                                          options:
                                                            _vm.codes.autoType,
                                                          placeholder: "전체",
                                                          reduce: function (
                                                            option
                                                          ) {
                                                            return option.code
                                                          },
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAutoType()
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.autoType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item,
                                                              "autoType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.autoType",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label:
                                              "설문항목 구성(환자% 유형만 해당)",
                                            "label-for":
                                              "survey-element-select-autos",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-select-autos",
                                                  value: _vm.item.autos
                                                    ? _vm.item.autos.length +
                                                      "개 항목"
                                                    : "0개 항목",
                                                  disabled: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.openAutoConfig()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "설문항목 기타포함여부",
                                            "label-for":
                                              "survey-element-required",
                                          },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "survey-element-is-auto-etc",
                                              disabled: !(
                                                _vm.item.autoType === 20 ||
                                                _vm.item.autoType === 21 ||
                                                _vm.item.autoType === 22
                                              ),
                                            },
                                            model: {
                                              value: _vm.item.isAutoEtc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item,
                                                  "isAutoEtc",
                                                  $$v
                                                )
                                              },
                                              expression: "item.isAutoEtc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: "설문항목 표시문구",
                                            "label-for":
                                              "survey-element-auto-description",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "survey-element-auto-description",
                                                  value:
                                                    _vm.item.autoDescription,
                                                  readonly: "",
                                                },
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      attrs: {
                                                        variant:
                                                          "outline-primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          _vm.openRichEditor(
                                                            "autoDescription",
                                                            _vm.item
                                                              .autoDescription,
                                                            "설문항목 표시문구 편집",
                                                            "입력한 표시문구([1],[2]..[n])는 이후 설문 답변시에 집계(계산) 결과 값으로 치환됩니다."
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" 변경 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-start",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(186, 191, 199, 0.15)",
                                                expression:
                                                  "'rgba(186, 191, 199, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "outline-secondary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return hide()
                                              },
                                            },
                                          },
                                          [_vm._v(" 닫기 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-end",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "primary",
                                              type: "submit",
                                            },
                                          },
                                          [_vm._v(" 적용(반영) ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
      _c("editor-popup", {
        attrs: {
          "show-editor": _vm.showEditor,
          text: _vm.editorText,
          title: _vm.editorTitle,
          description: _vm.editorDesc,
        },
        on: { close: _vm.editorConfig.close },
      }),
      _c("rich-editor-popup", {
        attrs: {
          "show-editor": _vm.showRichEditor,
          text: _vm.richText,
          title: _vm.richTitle,
          description: _vm.richDesc,
        },
        on: { close: _vm.richConfig.close },
      }),
      _c("element-auto-config", {
        attrs: {
          "show-auto-config": _vm.showAutoConfig,
          autos: _vm.autos,
          "auto-type": _vm.autoType,
        },
        on: { close: _vm.autoConfig.close },
      }),
      _c("element-choice-config", {
        attrs: {
          "show-choice-config": _vm.showChoiceConfig,
          choices: _vm.choices,
          "next-pages": _vm.item.nextPages,
          "next-elements": _vm.item.nextElements,
          "item-type": _vm.choiceConfig.itemType,
        },
        on: { close: _vm.choiceConfig.close },
      }),
      _c("element-choice-grid-config", {
        attrs: {
          "show-choice-grid-config": _vm.showChoiceGridConfig,
          choices: _vm.gridChoices,
          "item-type": _vm.choiceGridConfig.itemType,
        },
        on: { close: _vm.choiceGridConfig.close },
      }),
      _c("element-choice-input-config", {
        attrs: {
          "show-choice-input-config": _vm.showChoiceInputConfig,
          choices: _vm.inputChoices,
          "item-type": _vm.choiceInputConfig.itemType,
        },
        on: { close: _vm.choiceInputConfig.close },
      }),
      _c("element-group-config", {
        attrs: {
          "show-element-group-config": _vm.showElementGroupConfig,
          "group-elements": _vm.item.groupElements,
          "sub-element-count": _vm.item.groupSubElementCount,
        },
        on: { close: _vm.elementGroupConfig.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }