<!-- [팝업] 선택 항목 구성 -->
<template>
  <b-modal
    v-model="showChoiceConfig"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="선택 항목 편집"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <!-- <b-row
      class="m-1"
    >
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>"선택 시 표시할 문항"은 DB에 저장된 문항을 표시하고 있습니다.</h5>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>등록한 문항이 보이지 않을 경우, 페이지를 저장한 뒤 다시 확인하세요.</h5>
      </b-col>
    </b-row> -->
    <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        class="mb-1 d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="outline-secondary"
          size="sm"
          class="mr-50"
          @click.prevent="addChoice(true)"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>기타 항목 추가</span>
        </b-button>
        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="addChoice(false)"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>일반 항목 추가</span>
        </b-button>
      </b-col>
    </b-row>
    <validation-observer ref="formRules">
      <div
        v-for="(choice, idx) in choices"
        :id="`choice${idx}`"
        :key="choice.seq"
      >
        <b-row
          class="mt-1"
        >
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-center"
          >
            <b-badge variant="light-secondary">
              {{ choice.isEtc ? '기타' : `보기${idx + 1}` }}
            </b-badge>
          </b-col>
          <b-col
            :cols="itemType === 1 ? 8 : 10"
          >
            <validation-provider
              #default="{ errors }"
              :vid="`choice${idx}`"
              name="내용"
              rules="required"
            >
              <b-form-input
                v-model="choice.description"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start"
            :class="{'hidden': itemType !== 1}"
          >
            <b-form-checkbox
              v-model="choice.isFinish"
              class="custom-control-danger"
            >
              <label
                name="isFinish"
                style="font-size: 12px;"
              >
                *보상없이종료
              </label>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-secondary"
              size="sm"
              @click.prevent="removeChoice(choice)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-if="itemType === 1 || itemType === 2"
          class="mt-25"
        >
          <b-col cols="1">
            &nbsp;
          </b-col>
          <b-col cols="11">
            <v-select
              v-model="choice.showElementList"
              :options="nextElements"
              multiple
              :reduce="option => option.seq"
              placeholder="선택 시 표시할 문항 선택(옵션)"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="itemType === 1 || itemType === 2"
          class="mt-25"
        >
          <b-col cols="1">
            &nbsp;
          </b-col>
          <b-col cols="11">
            <v-select
              v-model="choice.linkPageList"
              :options="nextPages"
              multiple
              :reduce="option => option.seq"
              placeholder="선택 시 응답할 페이지 선택(옵션)"
            />
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { BBadge } from 'bootstrap-vue'
import {
  onMounted, getCurrentInstance,
} from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    vSelect,
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    showChoiceConfig: {
      type: Boolean,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
    nextPages: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    nextElements: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    itemType: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
    })

    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const choiceInitState = {
      id: null,
      seq: 0,
      description: '',
      isEtc: false,
      isFinish: false,
      showElements: null,
      showElementList: [],
      linkPages: null,
      linkPageList: [],
      isColumn: false,
    }
    const addChoice = isEtc => {
      const { choices } = props
      const newChoice = { ...choiceInitState }

      if (choices.length !== 0) {
        newChoice.seq = Math.max(...choices.map(o => (o.seq || 0))) + 1
      } else {
        newChoice.seq = 1
      }

      // 기타 항목이 존재하면 true
      const isExistEtc = choices.some(el => el.isEtc)

      if (isEtc) {
        if (isExistEtc) { return }

        newChoice.isEtc = isEtc
        newChoice.description = '기타'
        choices.push(newChoice)
      } else if (isExistEtc) {
        choices.splice(choices.length - 1, 0, newChoice)
      } else {
        choices.push(newChoice)
      }
    }

    const removeChoice = item => {
      const idx = props.choices.indexOf(item)
      if (idx !== -1) {
        props.choices.splice(idx, 1)
      }
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      const { choices } = props
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        const list = choices.map((x, idx) => Object.assign(x, { seq: (idx + 1) }))
        emit('close', list)
      })
    }

    return {
      required,
      close,
      submit,

      addChoice,
      removeChoice,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
