<template>
  <div
    class="mt-50 content-area-wrapper"
  >
    <div class="sidebar-left">
      <div class="sidebar">
        <div class="side-container">
          <b-list-group class="mt-1">
            <b-list-group-item
              v-for="elType in codes.elementType"
              :key="elType.code"
              style="cursor:pointer;"
              :disabled="pageId === 0 || readonly || isSortMode"
              @click.prevent="addPageElement(elType)"
            >
              <feather-icon
                :icon="elType.icon"
                size="18"
                class="mr-75"
              />
              <span class="align-text-bottom line-height-1">{{ elType.label }}</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
    <div class="content-right">
      <template v-if="pageId === 0"> <!-- 설문 설정 페이지 -->
        <header-view
          :header="paperInfo"
          :active="activeHeader"
          @click-header="headerView.click"
          @update-header="headerView.update"
        />
      </template>
      <template v-else>
        <b-row
          class="mt-50 mb-2"
        >
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-show="isSortMode"
              size="sm"
              variant="primary"
              class="ml-1"
              @click.prevent="saveSorting()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>순서 변경 저장</span>
            </b-button>
            <b-button
              v-show="isSortMode"
              size="sm"
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="changeSortMode(false)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
              />
              <span>취소</span>
            </b-button>
            <b-button
              v-show="!isSortMode && !readonly"
              size="sm"
              variant="outline-secondary"
              class="float-right"
              @click.prevent="changeSortMode(true)"
            >
              <feather-icon
                icon="ListIcon"
                class="mr-50"
              />
              정렬 순서 변경
            </b-button>
            <b-button
              v-show="!readonly"
              size="sm"
              variant="outline-secondary"
              class="float-right ml-1"
              @click.prevent="removePage"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              페이지 삭제
            </b-button>
          </b-col>
        </b-row>
        <!-- 페이지 헤더 -->
        <header-view
          :header="pageInfo"
          :active="activeHeader"
          @click-header="headerView.click"
          @update-header="headerView.update"
        />
        <b-alert
          :show="isSortMode"
          class="m-1"
          variant="warning"
        >
          <div
            class="alert-body"
            style="text-align:center;"
          >
            <span>문항 정렬 순서 변경이 활성화 되었습니다.</span>
          </div>
        </b-alert>
        <!-- 페이지 문항(SurveyElement) -->
        <draggable
          v-model="pageElements"
          :options="{disabled : !isSortMode}"
        >
          <transition-group
            class="grid-view wishlist-items"
          >
            <b-card
              v-for="(el, elIdx) in pageElements"
              :key="`el-${elIdx}`"
              :border-variant="(activeElement && currElement && currElement.seq === el.seq ? 'primary' : '')"
              :class="el.groupStyle ? getElementAreaStyle(el) : 'element-area'"
              :style="{ opacity: (el.isActive ? '1.0' : '0.3') }"
              @click.stop="clickElement(el)"
            >
              <template v-if="!el.title">
                <label><b>{{ el.typeName }}</b></label>
                <h5 class="mt-2">
                  문항 내용을 입력하려면 클릭하세요.
                </h5>
              </template>
              <template v-if="el.title">
                <h4>
                  <template v-if="el.type === 50">
                    <b-badge
                      variant="light-success"
                    >
                      그룹
                    </b-badge>
                  </template>
                  <template v-else>
                    <b-badge
                      v-if="el.isRequired"
                      variant="light-primary"
                    >
                      필수
                    </b-badge>
                    <b-badge
                      v-else
                      variant="light-secondary"
                    >
                      선택
                    </b-badge>
                  </template>
                  {{ el.title }}
                </h4>

                <!-- 문항 설명 문구(HTML) -->
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="mt-1"
                  v-html="el.description"
                />

                <!-- 선택(단일) -->
                <template v-if="el.type === 1">
                  <b-form-radio-group
                    value-field="seq"
                    text-field="description"
                    stacked
                  >
                    <b-form-radio
                      v-for="choice in el.choices"
                      :key="`${el.id}_${choice.seq}`"
                      v-model="el.tempVal"
                      :value="choice.seq"
                      class="mt-50"
                      @change="changeElementValue"
                    >
                      {{ choice.description }}
                    </b-form-radio>
                  </b-form-radio-group>
                </template>

                <!-- 선택(복수) -->
                <template v-if="el.type === 2">
                  <b-form-checkbox-group
                    value-field="seq"
                    text-field="description"
                    stacked
                  >
                    <b-form-checkbox
                      v-for="choice in el.choices"
                      :key="`${el.id}_${choice.seq}`"
                      v-model="el.tempVal"
                      :value="choice.seq"
                      class="mt-50"
                      @change="changeElementValue"
                    >
                      {{ choice.description }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </template>

                <!-- 텍스트(한줄) -->
                <template v-if="el.type === 3">
                  <b-form-input
                    v-model="el.tempVal"
                    :maxlength="el.maxCheck"
                    @click="tempClick"
                    @input="filteredValue(el)"
                    @change="changeElementValue"
                  />
                </template>

                <!-- 텍스트(여러줄) -->
                <template v-if="el.type === 4">
                  <b-form-textarea
                    v-model="el.tempVal"
                    :rows="el.rows || 5"
                    :maxlength="el.maxCheck"
                    @click="tempClick"
                    @input="filteredValue(el)"
                    @change="changeElementValue"
                  />
                </template>

                <!-- 순위형(Rank) -->
                <template v-if="el.type === 5">
                  <b-list-group>
                    <b-list-group-item
                      v-for="choice in el.choices"
                      :key="`${el.id}_${choice.seq}`"
                      :value="choice.seq"
                      class="d-flex justify-content-start align-items-center"
                      @click="clickRank(el, choice)"
                    >
                      <b-badge
                        v-if="choice.rank"
                        variant="primary"
                        pill
                        class="badge-round mr-50"
                      >
                        {{ choice.rank }}
                      </b-badge>
                      <span>{{ choice.description }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </template>

                <!-- 비율합계형(RateSum) -->
                <template v-if="el.type === 6">
                  <template
                    v-for="(choice, eChIdx) in el.choices"
                  >
                    <b-form-group
                      :key="`${el.id}_${choice.seq}`"
                      :label="`${(eChIdx + 1)}) ${choice.description}`"
                    >
                      <b-input-group
                        class="input-group-merge"
                        append="%"
                      >
                        <b-form-input
                          type="number"
                          class="text-right"
                        />
                      </b-input-group>
                    </b-form-group>
                  </template>
                </template>

                <!-- 격자형(Grid) -->
                <template v-if="el.type === 7">
                  <grid-choice
                    :el-seq="el.seq"
                    :sub-type="el.subType"
                    :choices="el.choices"
                  />
                </template>

                <!-- 척도형(Scale) -->
                <template v-if="el.type === 8">
                  <scale-view
                    :element="el"
                  />
                </template>

                <!-- 파이핑형(Piping) -->
                <template v-if="el.type === 9 && el.refElement">
                  <piping-view
                    :element="el"
                    :ref-element="el.refElement"
                  />
                </template>

                <!-- 자동 제출 -->
                <template v-if="el.type === 10">
                  <div
                    class="mt-1"
                    v-html="el.autoDescription"
                  />
                  <b-alert
                    show
                    class="mt-2"
                    variant="secondary"
                  >
                    <div class="alert-body">
                      <span>변수(자동제출 표시용)목록: 아래 표시된 변수명([1],[2]..[n])을 제목 또는 설문항목표시문구에 포함하세요.</span>
                    </div>
                  </b-alert>
                  <b-list-group>
                    <template v-if="el.autoType === 21 || el.autoType === 22 || el.autoType === 23">
                      <b-list-group-item
                        v-for="auto in el.autos"
                        :key="auto.seq"
                        :value="auto.seq"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <b-badge
                          variant="light-warning"
                          pill
                          class="badge-round mr-50"
                        >
                          [{{ auto.viewIndex }}]
                        </b-badge>
                        <span>{{ auto.codes }}</span>
                      </b-list-group-item>
                      <b-list-group-item
                        v-if="el.isAutoEtc"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <b-badge
                          variant="light-warning"
                          pill
                          class="badge-round mr-50"
                        >
                          [0]
                        </b-badge>
                        <span>기타</span>
                      </b-list-group-item>
                    </template>
                    <template v-else>
                      <b-list-group-item
                        class="d-flex justify-content-start align-items-center"
                      >
                        <b-badge
                          variant="light-warning"
                          pill
                          class="badge-round mr-50"
                        >
                          [1]
                        </b-badge>
                      </b-list-group-item>
                    </template>
                  </b-list-group>
                </template>
              </template>
            </b-card>
          </transition-group>
        </draggable>
      </template>
    </div>
    <element-edit-form
      v-model="activeElement"
      :item="currElement"
      @update="elementEditForm.update"
      @remove="elementEditForm.remove"
    />
  </div>
</template>

<script>
import axios from '@axios'
import draggable from 'vuedraggable'
import {
  ref, getCurrentInstance, onMounted, nextTick, watch,
} from '@vue/composition-api'
import {
  BAlert, BBadge, BListGroup, BListGroupItem, BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { clone } from '@core/utils/utils'
import { errorFormatter } from '@core/utils/filter'
import HeaderView from './HeaderView.vue'
import ElementEditForm from './ElementEditForm.vue'
import GridChoice from './element-view/GridChoice.vue'
import ScaleView from './element-view/ScaleView.vue'
import PipingView from './element-view/PipingView.vue'

export default {
  components: {
    draggable,
    BAlert,
    BBadge,
    BListGroup,
    BListGroupItem,
    BInputGroup,

    HeaderView,
    ElementEditForm,
    GridChoice,
    ScaleView,
    PipingView,
  },

  props: {
    paperInfo: {
      type: Object,
      required: true,
    },
    pageId: {
      type: Number,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    watch(() => props.pageId, () => {
      // 페이지 변경 시
      activeHeader.value = false
      activeElement.value = false

      if (props.pageId !== 0) fetchPage(props.pageId)
    })

    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal

    const pageInitState = {
      id: null,
      seq: null,
      title: null,
      description: null,
    }
    const pageInfo = ref({ ...pageInitState })

    const pageElements = ref([])
    watch(() => pageElements.value, () => {
      if (pageElements.value.length === 0) return

      setPipingRelation()
      arrangeActiveElements()
    })

    // Set Codes
    const elTypeIcons = [
      { icon: 'DiscIcon' }, // 선택(단일)
      { icon: 'CheckSquareIcon' }, // 선택(복수)
      { icon: 'TypeIcon' }, // 텍스트(한줄)
      { icon: 'TwitchIcon' }, // 텍스트(여러줄)
      { icon: 'ThumbsUpIcon' }, // 순위형
      { icon: 'DivideSquareIcon' }, // 비율합계형
      { icon: 'GridIcon' }, // 격자형
      { icon: 'GitCommitIcon' }, // 척도형
      { icon: 'GitPullRequestIcon' }, // 파이핑형
      { icon: 'ServerIcon' }, // 그룹
      { icon: 'LinkIcon' }, // 자동제출
    ]
    const codes = ref({
      elementType: [],
      autoType: [],
    })

    const fetchCodes = () => {
      axios
        .get("/fa/survey-paper/codes")
        .then(rs => {
          const { surveyElementType, surveyAutoType } = rs.data
          for (let i = 0; i < surveyElementType.length; i += 1) {
            surveyElementType[i].icon = elTypeIcons[i].icon
          }
          codes.value.elementType = surveyElementType.filter(el => el.code !== 100)
          codes.value.autoType = surveyAutoType
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    const fetchPage = id => {
      pageElements.value = []

      axios.get(`/fa/survey-paper/page/${id}`)
        .then(rs => {
          pageInfo.value = {
            id: rs.data.id,
            seq: rs.data.seq,
            title: rs.data.title,
            description: rs.data.description,
          }

          const { elements } = rs.data
          elements.forEach(element => {
            const el = element
            // 모든 문항은 활성으로 초기화
            el.isActive = true

            if (!el.choices) return

            el.choices.forEach(ch => {
              const choice = ch
              // 페이지 전환 시, 순위형(rank)외에 다른 항목들은 v-model이 설정되지 않아 모두 초기화 됨
              // 순위형도 동일하게 rank(화면에서 순위표시를 위한 속성) 값을 초기화 처리
              if (choice.rank) { choice.rank = null }
            })
          })

          pageElements.value = elements
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '페이지 정보 조회에 실패하였습니다.'))
        })
    }

    // 페이지 헤더(헤더제목, 인트로)
    const activeHeader = ref(false)
    const headerView = {
      click: () => {
        activeHeader.value = true
        activeElement.value = false
      },

      update: rs => {
        // 설문설정(pageId ==== 0)인 경우, 설문지 정보 업데이트
        if (props.pageId === 0) {
          emit('update-paper-info', rs)
          return
        }

        axios.post('/fa/survey-paper/update-page', {
          id: pageInfo.value.id,
          title: rs.title,
          description: rs.description,
        })
          .then(() => {
            pushToast('success', '페이지 정보 변경 완료')
            pageInfo.value.title = rs.title
            pageInfo.value.description = rs.description
          })
          .catch(error => {
            pushToast('danger', errorFormatter(error, '페이지 정보 변경 과정에서 오류가 발생하였습니다.'))
          })
      },
    }
    // 페이지 헤더(헤더제목, 인트로) 수정 폼 End.

    const getElementAreaStyle = element => {
      const { groupStyle } = element
      const elements = pageElements.value

      // 현재 문항 기준 다음 문항
      const nextElement = elements[elements.findIndex(el => el.seq === element.seq) + 1] || null

      if (groupStyle === 'top') {
        const haveSubElement = nextElement && nextElement.groupStyle === 'sub'
        return haveSubElement ? `element-area ${groupStyle}` : 'element-area'
      }

      if (groupStyle === 'sub') {
        const isLast = !(nextElement && nextElement.groupStyle === 'sub')

        return `element-area ${groupStyle}${isLast ? ' last' : ''}`
      }

      return 'element-area'
    }

    const activeElement = ref(false)
    const currElement = ref({})

    const clickElement = el => {
      if (isSortMode.value) return // 정렬 순서 변경 상태에서 문항 편집 불가

      bindElement(el)
    }

    const bindElement = element => {
      if (element) {
        const elements = pageElements.value
        const cloneElement = clone(element)

        const { pageId, pages } = props
        const currPageSeq = pages.find(p => p.id === pageId).seq

        cloneElement.nextPages = pages
          .filter(p => p.seq >= currPageSeq)
          .map(p => ({
            seq: p.seq,
            label: `${p.seq} 페이지${(p.seq === currPageSeq ? '(현재 페이지에서 설문을 종료하는 경우 선택)' : '')}`,
          }))

        // 파이핑에서 사용
        cloneElement.prevElements = elements
          .filter(el => el.seq < cloneElement.seq)
          .map(el => ({
            id: el.id, type: el.type, seq: el.seq, label: el.title,
          }))

        // 선택(단일), 선택(복수), 순위형에서 사용 (연결된 표시 문항 선택)
        cloneElement.nextElements = elements
          .filter(el => el.seq > cloneElement.seq)
          .map(el => ({
            id: el.id, type: el.type, seq: el.seq, label: el.title,
          }))

        // 그룹 대상 문항
        if (cloneElement.type === 50) {
          let isNextGroupElement = false
          const groupElements = elements
            .filter(el => {
              if (isNextGroupElement) return false
              if (el.seq <= cloneElement.seq) return false // 현재 문항보다 이후 순서 문항만
              if (el.type === 50) {
                isNextGroupElement = true // 다른 그룹 문항이 포함된 경우 (이전 문항까지만 포함)
                return false
              }

              return true
            })

          cloneElement.groupSubElementCount = groupElements.filter(el => el.groupStyle === 'sub').length
          cloneElement.groupElements = groupElements.map(el => ({
            id: el.id,
            type: el.type,
            seq: el.seq,
            label: el.title,
            groupStyle: el.groupStyle,
          }))
        }

        currElement.value = cloneElement
        activeElement.value = true
      } else {
        // clear
        currElement.value = {}
        activeElement.value = false
      }

      activeHeader.value = false // 헤더 편집 창이 띄어져 있는 경우 닫음
    }

    const clickRank = (el, item) => {
      const element = el
      const { choices } = element
      let answers = choices.filter(ch => ch.rank > 0).sort((a, b) => a.rank - b.rank)

      if (answers.includes(item)) {
        answers = answers.filter(answer => answer !== item)
      } else {
        answers.push(item)
      }

      choices.forEach(ch => {
        const choice = ch
        choice.rank = null
      })
      const activeSeqs = answers.map((answer, i) => {
        const choice = choices.find(ch => ch.seq === answer.seq)
        if (choice) choice.rank = i + 1
        return choice?.seq
      })

      element.tempVal = activeSeqs.filter(Boolean)
      changeElementValue(element.tempVal)
      instance.proxy.$forceUpdate()
    }

    // 연결된 표시 문항 적용
    const arrangeActiveElements = () => {
      const elements = pageElements.value

      elements.forEach(element => {
        const el = element
        // 모든 문항을 활성으로 초기화
        el.isActive = true
      })

      const keepShowElements = []
      for (let i = 0; i < elements.length; i += 1) {
        const element = elements[i]
        if (element.type === 1 || element.type === 2 || element.type === 5) {
          // 선택형 문항(라디오, 체크박스, 순위형)에 대한 처리
          const { choices } = element
          for (let j = 0; j < choices.length; j += 1) {
            const ch = choices[j]
            let showElements = null
            if (ch.showElementList && ch.showElementList.length) {
              showElements = ch.showElementList
            } else {
              // showElements 없으면, 건너 뜀
              // eslint-disable-next-line no-continue
              continue
            }

            let isCheck = false
            if (element.tempVal && element.tempVal === ch.seq) {
              isCheck = true
            } else if (element.tempVal && typeof element.tempVal === 'object' && element.tempVal.indexOf(ch.seq) !== -1) {
              isCheck = true
            }

            for (let k = 0; k < elements.length; k += 1) {
              if (showElements.indexOf(elements[k].seq) !== -1) {
                if (isCheck && keepShowElements.indexOf(elements[k].seq) === -1) {
                  keepShowElements.push(elements[k].seq)
                }

                if (!isCheck && keepShowElements.indexOf(elements[k].seq) !== -1) {
                  elements[k].isActive = true
                } else {
                  elements[k].isActive = isCheck
                }
              }
            }
          } // for j end.
        } else if (element.type === 3 || element.type === 4) {
          // 텍스트, 코멘트 유형에 대한 처리
          let inputShowElements = null
          if (element.inputShowElementList) {
            inputShowElements = element.inputShowElementList
          } else {
            // eslint-disable-next-line no-continue
            continue
          }
          // 텍스트 유형은 한 글자라도 입력하면 true
          const isCheck = !!element.tempVal
          for (let j = 0; j < elements.length; j += 1) {
            if (inputShowElements.indexOf(elements[j].seq) !== -1) {
              // InputShowElements에 속한 문항은 숨김 처리
              elements[j].isActive = isCheck
            }
          }
        }
      } // for i end.

      instance.proxy.$forceUpdate()
    }

    // 문항 삭제 시 연결된 표시 문항 초기화
    const rebindActiveElements = element => {
      const el = element

      const hideElements = []
      if (el.inputShowElementList && el.inputShowElementList.length) {
        el.inputShowElementList.forEach(seq => hideElements.push(seq))
      }
      if (el.choices) {
        el.choices.forEach(ch => {
          const choice = ch
          if (choice.showElementList) {
            choice.showElementList.forEach(seq => hideElements.push(seq))
          }
        })
      }

      const elements = pageElements.value
      elements.forEach(e => {
        if (hideElements.find(seq => seq === e.seq)) {
          // 연결문항이 설정된 문항을 삭제하였을 때, 귀속된 피 연결 문항은 모두 활성 상태로 변경
          e.isActive = true
        }
      })

      instance.proxy.$forceUpdate()
    }

    // 파이핑 관계 삽입
    const setPipingRelation = () => {
      const elements = pageElements.value

      elements.forEach(el => {
        if (el.type === 9 && el.refElementId) {
          const pipingElement = el
          pipingElement.refElement = elements.find(e => e.id === el.refElementId)
        }
      })
    }

    // @click 이벤트 버블링 발생시켜 영역이 강제 선택되도록 함
    // 이벤트 핸들러는 lint 에러 방지용으로 의미 없음
    const tempClick = () => {}

    const filteredValue = element => {
      const el = element
      let inputValue = el.tempVal

      if (el.maxCheck && inputValue.length > el.maxCheck) {
        inputValue = inputValue.slice(0, el.maxCheck)
      }
      if (el.textType === 1) {
        inputValue = inputValue.replace(/[0-9]/g, '')
      }
      if (el.textType === 2) {
        inputValue = inputValue.replace(/[^0-9]/g, '')
      }

      nextTick(() => {
        el.tempVal = inputValue
      })
    }

    // (연결 문항 활성/비활성 처리를 위해) 값 변경 시 부모 호출
    const changeElementValue = val => {
      arrangeActiveElements()

      emit('change-value', val)
    }

    const elementInitState = {
      id: null,
      seq: 0,
      type: null,
      typeName: null,
      title: '',
      description: '',
      isRequired: true,
      minCheck: null,
      maxCheck: null,
      rows: 5,
      textType: null,
      inputShowElements: null,
      inputShowElementList: [],
      finishMessage: null,
      subType: null,
      sumCheck: null,
      isRandomOrder: false,
      scaleLevel: null,
      minValue: null,
      minDesc: null,
      medDesc: null,
      maxDesc: null,
      groupStyle: null,
      refElementId: null,
      refElement: null,
      choices: [],
    }
    const addPageElement = elType => {
      const elements = pageElements.value
      const newElement = { ...elementInitState }

      if (elements.length !== 0) {
        newElement.seq = Math.max(...elements.map(o => (o.seq || 0))) + 1
      } else {
        newElement.seq = 1
      }

      newElement.type = elType.code
      newElement.typeName = elType.label
      newElement.isActive = true

      // 비율 합계형
      if (newElement.type === 6) newElement.sumCheck = 100 // 비율 합계 기본 값

      // 격자형
      if (newElement.type === 7) newElement.subType = 1 // 문항 선택 기본 값: 단일 선택(1)

      // 척도형
      if (newElement.type === 8) {
        newElement.scaleLevel = 3 // 척도 단계
        newElement.minValue = 0 // 시작 점수
      }

      // 파이핑형
      if (newElement.type === 9) newElement.subType = 1 // 문항 선택 기본 값: 단일 선택(1)

      // 그룹
      if (newElement.type === 50) {
        newElement.isRequired = false // 그룹은 답변 입력이 없으므로 필수로 지정하지 않음
        newElement.groupStyle = 'top' // 그룹의 그룹스타일은 'top'으로 고정
      }

      elements.push(newElement)
    }

    const elementEditForm = {
      update: async rs => {
        if (props.readonly) {
          pushToast('warning', '현재 진행 중인 설문으로 문항정보는 변경할 수 없습니다.')
          return
        }

        const paramElement = rs

        let elementId = null

        if (paramElement.id) elementId = await updateElement(paramElement)
        else elementId = await addElement(paramElement)

        if (elementId) {
          // 문항 신규 저장(또는 업데이트) 성공 시 처리

          arrangeActiveElements() // 문항 표시 연결 재 적용

          if (paramElement.type === 50
            && paramElement.groupStartElementSeq && paramElement.groupEndElementSeq) {
            updateStyleSubElements(paramElement, 'sub')

            // 그룹 문항 범위 변경 시, 변경된 문항 범위에 해당하지 않는 문항의 그룹스타일 초기화 처리
            const clearSubElementSeqs = paramElement.groupElements
              .filter(el => el.seq > paramElement.groupEndElementSeq && el.groupStyle === 'sub')
              .map(el => el.seq)

            if (clearSubElementSeqs.length > 0) {
              paramElement.groupStartElementSeq = Math.min(...clearSubElementSeqs)
              paramElement.groupEndElementSeq = Math.max(...clearSubElementSeqs)

              updateStyleSubElements(paramElement, null)
            }
          } // 그룹 지정 처리 end.
        }
      },

      remove: async rs => {
        if (props.readonly) {
          pushToast('warning', '현재 진행 중인 설문으로 문항정보는 변경할 수 없습니다.')
          return
        }

        const paramElement = rs

        let result = false

        if (paramElement.id) result = await removeElement(paramElement)
        else result = true

        if (result) {
          // 문항 삭제 후 처리
          if (paramElement.type === 50) clearStyleSubElements(paramElement)

          // 삭제 된 문항에 연결 된 표시 문항 해제
          rebindActiveElements(paramElement)

          const elements = pageElements.value
          elements.forEach(element => {
            const el = element

            if (el.refElementId && el.refElementId === paramElement.id) {
              el.refElement = null
              el.refElementId = null
            }
          })

          const targetIdx = elements.findIndex(o => o.seq === paramElement.seq)
          elements.splice(targetIdx, 1)

          bindElement(null)
        }
      },
    }

    let ogPageElements = null
    const isSortMode = ref(false)
    const changeSortMode = isSort => {
      if (isSort) {
        const elements = pageElements.value

        const haveNonSaveElement = elements.some(e => !e.id)
        if (haveNonSaveElement) {
          pushToast('warning', '입력되지 않은 빈 문항이 있습니다. 빈 문항 입력 후 정렬 순서 변경 버튼을 클릭하세요.')
          return
        }

        ogPageElements = [...elements]
      } else {
        pageElements.value = [...ogPageElements] // 정렬 취소 시 이전 상태로 복원
      }
      isSortMode.value = isSort
    }

    const saveSorting = () => {
      bvModal
        .msgBoxConfirm('문항에 연결된 문항이 있을 경우 모두 초기화됩니다. 저장하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          const elements = pageElements.value

          axios.post('/fa/survey-paper/change-element-order', elements.map(x => x.id))
            .then(() => {
              pushToast('success', '정렬 순서 변경 완료')
              isSortMode.value = false

              fetchPage(pageInfo.value.id)
            })
            .catch(error => {
              pushToast('danger', errorFormatter(error, '문항 정렬 순서 변경 과정에서 오류가 발생하였습니다.'))
              changeSortMode(false)
            })
        })
    }

    const removePage = () => {
      bvModal
        .msgBoxConfirm('페이지에 등록한 모든 문항이 함께 삭제되며, 다른 페이지에서 설정한 페이지 연결이 모두 초기화됩니다. 삭제하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/survey-paper/remove-page/${pageInfo.value.id}`)
            .then(() => {
              pushToast('success', '페이지 삭제 완료')
              emit('remove-page')
            })
            .catch(error => {
              pushToast('danger', errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'))
            })
        })
    }
    // 페이지(탭) 영역 제어 End.

    const toElementParam = el => {
      if (el.choices) {
        el.choices.forEach(ch => {
          const choice = ch

          if (choice.linkPageList) {
            choice.linkPages = choice.linkPageList.sort((a, b) => a - b).join(',')
          }
          if (choice.showElementList) {
            choice.showElements = choice.showElementList.sort((a, b) => a - b).join(',')
          }
        })
      }

      const param = {
        surveyPageId: pageInfo.value.id,
        seq: el.seq,
        type: el.type,
        title: el.title,
        description: el.description,
        isRequired: el.isRequired,
        minCheck: el.minCheck,
        maxCheck: el.maxCheck,
        rows: el.rows,
        surveyPaperId: props.paperInfo.id,
        textType: el.textType,
        inputShowElements: el.inputShowElementList.join(','),
        finishMessage: el.finishMessage,
        subType: el.subType,
        sumCheck: el.sumCheck,
        isRandomOrder: el.isRandomOrder,
        scaleLevel: el.scaleLevel,
        minValue: el.minValue,
        minDesc: el.minDesc,
        medDesc: el.medDesc,
        maxDesc: el.maxDesc,
        groupStyle: el.groupStyle,
        refElementId: el.refElementId,
        choices: el.choices,
      }

      param.id = el.id ? el.id : 0

      return param
    }

    const addElement = async el => {
      try {
        const rs = await axios.post('/fa/survey-paper/add-element', toElementParam(el))

        const paramElement = el
        if (rs.data.id) {
          paramElement.id = rs.data.id
        }

        const elements = pageElements.value
        const targetIdx = elements.findIndex(o => o.seq === paramElement.seq)
        elements.splice(targetIdx, 1, paramElement)
        pushToast('success', '문항 업데이트 완료')

        return paramElement.id
      } catch (error) {
        pushToast('danger', errorFormatter(error, '문항을 등록하는 과정에서 오류가 발생하였습니다.'))
        return null
      }
    }

    const updateElement = async el => {
      try {
        await axios.post('/fa/survey-paper/update-element', toElementParam(el))

        const paramElement = el

        const elements = pageElements.value
        const targetIdx = elements.findIndex(o => o.seq === paramElement.seq)
        elements.splice(targetIdx, 1, paramElement)
        pushToast('success', '문항 업데이트 완료')

        return paramElement.id
      } catch (error) {
        pushToast('danger', errorFormatter(error, '문항 정보를 변경하는 과정에서 오류가 발생하였습니다.'))
        return null
      }
    }

    const removeElement = async el => {
      try {
        await axios.delete(`/fa/survey-paper/remove-element/${el.id}`)

        pushToast('success', '문항 삭제 완료')

        return true
      } catch (error) {
        pushToast('danger', errorFormatter(error, '문항을 삭제하는 과정에서 오류가 발생하였습니다.'))
        return false
      }
    }

    // (그룹 문항 삭제 시) 하위 문항 그룹 스타일 지정 제거
    const clearStyleSubElements = element => {
      const groupEl = element
      const elements = pageElements.value
      let isNextGroupElement = false

      const subElementSeqs = elements
        .filter(el => {
          if (isNextGroupElement) return false
          if (el.seq <= groupEl.seq) return false // 그룹 문항보다 이후 순서 문항만
          if (el.type === 50) {
            isNextGroupElement = true // 다른 그룹 문항이 포함된 경우 (이전 문항까지만 포함)
            return false
          }

          return (el.groupStyle === 'sub')
        })
        .map(el => el.seq)

      if (subElementSeqs.length === 0) return

      groupEl.groupStartElementSeq = Math.min(...subElementSeqs)
      groupEl.groupEndElementSeq = Math.max(...subElementSeqs)

      if (!groupEl.groupStartElementSeq || !groupEl.groupEndElementSeq) return

      updateStyleSubElements(groupEl, null)
    }

    const updateStyleSubElements = (groupEl, groupStyle) => {
      const elements = pageElements.value

      const subElements = elements.filter(el => el.seq >= groupEl.groupStartElementSeq && el.seq <= groupEl.groupEndElementSeq)

      subElements.forEach(element => {
        const el = element
        el.groupStyle = groupStyle
      })

      const elementIds = subElements.filter(x => x.id).map(x => x.id)
      if (elementIds.length === 0) return

      // DB에 저장된 하위 문항은 스타일 업데이트
      axios.post('/fa/survey-paper/change-element-group-style', {
        elementIds,
        groupStyle,
      })
        .then(() => {
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '그룹에 속한 하위 문항 업데이트 과정에서 오류가 발생하였습니다.'))
        })
    }

    return {
      codes,

      required,
      addPageElement,
      removePage,

      headerView,
      activeHeader,
      activeElement,

      isSortMode,
      changeSortMode,
      saveSorting,

      pageInfo,
      pageElements,

      clickRank,
      clickElement,
      changeElementValue,
      tempClick,
      filteredValue,

      currElement,
      elementEditForm,
      getElementAreaStyle,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/views/vertical-layout.scss';
</style>

<style>
.modal-xl {
  margin-left: auto;
  margin-right: auto;
  width: 1440px;
}

.element-area.top {
  margin-bottom: 0px;
}

.element-area.sub {
  margin: 8px 42px;
}

.element-area.sub.last {
  margin-bottom: 2rem;
}
</style>
