<!-- [팝업] 선택 항목 구성 -->
<template>
  <b-modal
    v-model="showChoiceGridConfig"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="입력 항목 편집"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <validation-observer ref="formRules">
      <span>소질문(행 *최대 50자까지 입력 가능)</span>
      <div
        v-for="(choice, idx) in filteredChoices(false)"
        :id="`choice-row-${idx}`"
        :key="`row-${choice.seq}`"
      >
        <b-row
          class="mt-50"
        >
          <b-col
            cols="10"
          >
            <validation-provider
              #default="{ errors }"
              :vid="`choice-row-${idx}`"
              name="소질문(행)"
              rules="required"
            >
              <b-form-input
                v-model="choice.description"
                placeholder="질문 내용 입력"
                maxlength="50"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start"
          >
            <b-button
              v-if="idx === 0"
              variant="outline-primary"
              @click.prevent="addChoice(false)"
            >
              추가 입력
            </b-button>
            <b-button
              v-else
              variant="outline-secondary"
              @click.prevent="removeChoice(choice)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="mt-1 mb-1" />
      <span>선택 항목(열 *최대 50자까지 입력 가능)</span>
      <div
        v-for="(choice, idx) in filteredChoices(true)"
        :id="`choice-col-${idx}`"
        :key="`col-${choice.seq}`"
      >
        <b-row
          class="mt-50"
        >
          <b-col
            cols="10"
          >
            <validation-provider
              #default="{ errors }"
              :vid="`choice-col-${idx}`"
              name="선택 항목(열)"
              rules="required"
            >
              <b-form-input
                v-model="choice.description"
                placeholder="선택 항목 입력"
                maxlength="50"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start"
          >
            <b-button
              v-if="idx === 0"
              variant="outline-primary"
              @click.prevent="addChoice(true)"
            >
              추가 입력
            </b-button>
            <b-button
              v-else
              variant="outline-secondary"
              @click.prevent="removeChoice(choice)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  onMounted, getCurrentInstance, watch,
} from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    showChoiceGridConfig: {
      type: Boolean,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
      // default: () => ([]),
    },
    itemType: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    watch(() => props.choices, () => {
      if (props.choices && props.choices.length === 0) {
        addChoice(true)
        addChoice(false)
      }
    })

    onMounted(() => {
    })

    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const filteredChoices = isColumn => {
      const { choices } = props

      return choices.filter(ch => ch.isColumn === isColumn).sort((a, b) => a.seq - b.seq)
    }

    const choiceInitState = {
      id: null,
      seq: 0,
      description: '',
      isEtc: false,
      isFinish: false,
      showElements: null,
      showElementList: [],
      linkPages: null,
      linkPageList: [],
      isColumn: false,
    }
    const addChoice = isColumn => {
      const { choices } = props
      const filtChoices = filteredChoices(isColumn)

      const newChoice = { ...choiceInitState }
      newChoice.isColumn = isColumn

      if (filtChoices.length !== 0) {
        newChoice.seq = Math.max(...filtChoices.map(o => (o.seq || 0))) + 1
      } else {
        newChoice.seq = 1
      }

      choices.push(newChoice)
    }

    const removeChoice = item => {
      const idx = props.choices.indexOf(item)
      if (idx !== -1) {
        props.choices.splice(idx, 1)
      }
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      const { choices } = props
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        let colTrue = 0
        let colFalse = 0
        const list = choices.map(x => {
          if (x.isColumn) {
            colTrue += 1
            return Object.assign(x, { seq: colTrue })
          }
          colFalse += 1
          return Object.assign(x, { seq: colFalse })
        })

        emit('close', list)
      })
    }

    return {
      required,
      close,
      submit,

      addChoice,
      removeChoice,

      filteredChoices,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
