<!-- [팝업] 선택 항목 구성 -->
<template>
  <b-modal
    v-model="showChoiceInputConfig"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="입력 항목 편집"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="mt-25"
    >
      <b-col
        cols="6"
        class="d-flex align-items-center justify-content-start"
      >
        <label>*최대 50자까지 입력 가능</label>
      </b-col>
      <b-col
        cols="6"
        class="mb-1 d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="addChoice"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>항목 추가</span>
        </b-button>
      </b-col>
    </b-row>
    <validation-observer ref="formRules">
      <div
        v-for="(choice, idx) in choices"
        :id="`choice${idx}`"
        :key="choice.seq"
      >
        <b-row
          class="mt-1"
        >
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-center"
          >
            <b-badge variant="light-secondary">
              {{ `항목${idx + 1}` }}
            </b-badge>
          </b-col>
          <b-col
            cols="10"
          >
            <validation-provider
              #default="{ errors }"
              :vid="`choice${idx}`"
              name="항목"
              rules="required"
            >
              <b-form-input
                v-model="choice.description"
                maxlength="50"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-secondary"
              size="sm"
              @click.prevent="removeChoice(choice)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import {
  onMounted, getCurrentInstance,
} from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    showChoiceInputConfig: {
      type: Boolean,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
      // default: () => ([]),
    },
    itemType: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
    })

    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const choiceInitState = {
      id: null,
      seq: 0,
      description: '',
      isEtc: false,
      isFinish: false,
      showElements: null,
      showElementList: [],
      linkPages: null,
      linkPageList: [],
      isColumn: false,
    }
    const addChoice = () => {
      const { choices } = props
      const newChoice = { ...choiceInitState }

      if (choices.length !== 0) {
        newChoice.seq = Math.max(...choices.map(o => (o.seq || 0))) + 1
      } else {
        newChoice.seq = 1
      }

      choices.push(newChoice)
    }

    const removeChoice = item => {
      const idx = props.choices.indexOf(item)
      if (idx !== -1) {
        props.choices.splice(idx, 1)
      }
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      const { choices } = props
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        const list = choices.map((x, idx) => Object.assign(x, { seq: (idx + 1) }))
        emit('close', list)
      })
    }

    return {
      required,
      close,
      submit,

      addChoice,
      removeChoice,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
